@tailwind base;
@tailwind components;
@tailwind utilities;

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  position: relative;
  background-color: #fff;
}

input[type="checkbox"]:checked {
  background-color: #4caf50;
  border-color: #4caf50;
}

input[type="checkbox"]:checked::after {
  content: "";
  position: absolute;
  left: 6px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #ffffff;
  border: 1px solid #ccc;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap");
